import React from 'react';

import { Box, Button, Stack, useToast } from '@chakra-ui/react';
import InputField from '../ui/textField';
import Logo from '../sidebar/logo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLoginMutation } from '../../api';
import { useDispatch } from 'react-redux';
import { loginReducer } from '../../redux/slices/app.slice';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export default function LoginForm() {
  const [, setCookie] = useCookies(['token']);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      await login(values)
        .then((data: any) => {
          if (data.error) {
            throw new Error(
              data?.error?.data?.message || 'Something went wrong',
            );
          }
          dispatch(loginReducer(data.data));
          setCookie('token', data.data.token, { path: '/', maxAge: 3600 * 7 });
          toast({
            title: 'Login Successful',
            description: 'You have successfully logged in',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          navigate('/dashboard');
        })
        .catch(err => {
          toast({
            title: 'Login Failed',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        });
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        padding: '20px 20px 8%',
        form: {
          width: '100%',
          maxWidth: '400px',
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={5} w="100%" maxW="400px">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Logo />
          </Box>
          <InputField
            name="username"
            label="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && formik.errors.username}
            size="lg"
          />
          <InputField
            name="password"
            label="Password"
            type="password"
            size="lg"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && formik.errors.password}
          />
          <Button
            isLoading={isLoading}
            loadingText="Logging in..."
            mt={4}
            type="submit"
            colorScheme="blue"
            size="lg"
          >
            Login
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
