import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL || '';

export const Api = createApi({
  reducerPath: 'api',
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: 'include',
    prepareHeaders: headers => {
      const cookie = document.cookie;
      const token = cookie.split(';').find(c => c.includes('token'));
      if (token) {
        headers.set('Authorization', `${token.split('=')[1]}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
    }),
    authenticate: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/authenticate',
        method: 'POST',
      }),
    }),
    createCategory: builder.mutation({
      query: body => ({
        url: '/categories',
        method: 'POST',
        body,
      }),
    }),
    getCategories: builder.query({
      query: filter => ({
        url: '/categories',
        method: 'GET',
        params: filter,
      }),
    }),
    getCategory: builder.query({
      query: id => `/categories/${id}`,
    }),
    updateCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `/categories/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteCategory: builder.mutation({
      query: id => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
    }),
    createPortfolio: builder.mutation({
      query: body => ({
        url: '/portfolios',
        method: 'POST',
        body,
      }),
    }),
    getPortfolios: builder.query({
      query: filter => ({
        url: '/portfolios',
        method: 'GET',
        params: filter,
      }),
    }),
    getPortfolio: builder.query({
      query: id => `/portfolios/${id}`,
    }),
    updatePortfolio: builder.mutation({
      query: ({ id, body }) => ({
        url: `/portfolios/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deletePortfolio: builder.mutation({
      query: id => ({
        url: `/portfolios/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadMedia: builder.mutation({
      query: ({ id, body }) => ({
        url: `/media`,
        method: 'POST',
        body,
      }),
    }),
    deleteMedia: builder.mutation({
      query: body => ({
        url: `/media`,
        method: 'DELETE',
        body,
      }),
    }),
    getMedia: builder.query({
      query: filter => ({
        url: '/media',
        method: 'GET',
        params: filter,
      }),
      keepUnusedDataFor: 0.0001,
    }),
  }),
});

export const {
  useLoginMutation,
  useAuthenticateMutation,
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useLazyGetCategoryQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreatePortfolioMutation,
  useGetPortfoliosQuery,
  useLazyGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useUploadMediaMutation,
  useDeleteMediaMutation,
  useGetMediaQuery,
} = Api;
