import { Box, Container } from '@chakra-ui/react';
import React from 'react';
import CategoriesForm from '../components/forms/categoriesForm';

export default function AddBlog() {
  return (
    <Box>
      <Container maxW="container.xl">
        <CategoriesForm />
      </Container>
    </Box>
  );
}
