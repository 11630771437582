import React from 'react';
import { IoArrowBackOutline, IoArrowForward } from 'react-icons/io5';

import ReactPaginate from 'react-paginate';
import { Box } from '@chakra-ui/react';

interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const { currentPage, onPageChange, totalPages } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: totalPages > 1 ? 6 : 0,
        '.pagination': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          li: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: 'md',
            transition: 'all 0.3s ease',
            '&:hover': {
              bg: 'gray.700',
            },
            '&.selected': {
              bg: 'gray.700',
            },
            a: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              borderRadius: 'md',
              border: '1px solid',
              borderColor: 'gray.700',
              height: '40px',
              minWidth: '40px',
              transition: 'all 0.3s ease',
              '&:hover': {
                bg: 'gray.700',
              },
            },
          },
        },
      }}
    >
      {totalPages > 1 && (
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={page => onPageChange(page.selected + 1)}
          className="pagination"
          initialPage={currentPage - 1}
          activeClassName="selected"
          previousLabel={<IoArrowBackOutline size={20} />}
          nextLabel={<IoArrowForward size={20} />}
        />
      )}
    </Box>
  );
}
