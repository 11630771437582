/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Box, Button, Stack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const sizeConverterInMb = (size: number) => {
  return `${(size / 1000000).toFixed(2)} MB`;
};

interface Props {
  image: any;
  onSuccess: () => void;
  onRemove: () => void;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
}

export default function UploaderPreview({
  image,
  onSuccess,
  onRemove,
  setIsUploading: setIsUploadingProp,
}: Props) {
  const [cookies] = useCookies(['token']);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();
  const [progress, setProgress] = useState(0);

  const onSubmit = async () => {
    setIsUploadingProp(true);
    setIsUploading(true);
    const formData = new FormData();
    for (let i = 0; i < image.length; i++) {
      formData.append('file', image[i]);
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${cookies.token}`,
        },
        onUploadProgress: progressEvent => {
          if (!progressEvent?.total) return;
          setProgress(
            Math.round(
              (progressEvent.loaded / progressEvent?.total || 0) * 100,
            ),
          );
        },
      });
      onSuccess();
      toast({
        title: 'Media uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsUploadingProp(false);
      setIsUploading(false);
    } catch (error: any) {
      toast({
        title: error?.response?.data?.message || 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsUploadingProp(false);
      setIsUploading(false);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: {
          base: 'flex-start',
          sm: 'center',
        },
        justifyContent: 'space-between',
        borderRadius: '10px',
        border: '1px dashed',
        borderColor: 'gray.600',
        gap: '20px',
        flexDirection: {
          base: 'column',
          sm: 'row',
        },
        p: {
          base: 4,
          sm: 2,
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Box
          sx={{
            img: {
              borderRadius: '10px',
              width: '50px !important',
              height: '50px !important',
              objectFit: 'cover',
            },
          }}
        >
          {image?.length === 1 ? (
            <>
              {image[0].type.includes('image') ? (
                <Box
                  as="img"
                  src={URL.createObjectURL(image[0])}
                  alt="preview"
                  width={50}
                  height={50}
                />
              ) : (
                <Box
                  sx={{
                    video: {
                      borderRadius: '10px',
                      width: '50px !important',
                      height: '50px !important',
                      objectFit: 'cover',
                    },
                  }}
                >
                  <video width="50" height="50">
                    <source
                      src={URL.createObjectURL(image[0])}
                      type={image[0].type}
                    />
                  </video>
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                fontWeight: 'bold',
                color: 'gray.200',
                fontSize: 'sm',
                pl: 2,
              }}
            >
              {image?.length} media selected
            </Box>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              fontSize: 'sm',
              fontWeight: 'bold',
              color: 'gray.200',
              textTransform: 'capitalize',
            }}
          >
            {image.name}
          </Box>
          <Box
            sx={{
              fontSize: 'xs',
              color: 'gray.300',
            }}
          >
            {sizeConverterInMb(
              image
                .map((i: any) => i.size)
                .reduce((a: any, b: any) => a + b, 0),
            )}
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: {
            base: '100%',
            sm: 'auto',
          },
        }}
        direction="row"
        spacing={3}
      >
        <Button
          onClick={onSubmit}
          disabled={isUploading}
          isLoading={isUploading}
          loadingText={`Uploading... ${progress}%`}
          sx={{
            borderRadius: '10px',
            transition: 'all 0.3s ease',
            flex: {
              base: '1',
              sm: 'initial',
            },
          }}
        >
          Upload
        </Button>
        {!isUploading && (
          <Button
            onClick={onRemove}
            variant="delete"
            sx={{
              borderRadius: '10px',
              transition: 'all 0.3s ease',
              backgroundColor: 'red.500 !important',
              flex: {
                base: '1',
                sm: 'initial',
              },
            }}
          >
            Remove
          </Button>
        )}
      </Stack>
    </Box>
  );
}
