import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
    direction: 'ltr',
    fonts: {
      heading: "'Open Sans', sans-serif",
      body: "'Open Sans', sans-serif",
    },
    styles: {
      global: () => ({
        'html, body': {
          scrollBehavior: 'smooth',
          backgroundColor: 'gray.900 !important',
        },
        body: {
          color: 'white',
          transision: 'none !important',
          overflowX: 'hidden',
        },
        'html body .chakra-alert': {
          background: 'white',
          borderRadius: '13px',
          border: 'none',
          color: 'black',
          fontSize: '13px',
          padding: '10px 30px 10px 10px',
          'button[aria-label="Close"]': {
            top: '10px',
            right: '8px',
          },
          '.chakra-alert__icon[data-status="info"]': {
            color: 'primary.500',
          },
          '.chakra-alert__icon[data-status="warning"]': {
            color: 'yellow.500',
          },
          '.chakra-alert__icon[data-status="success"]': {
            color: 'green.500',
          },
          '.chakra-alert__icon[data-status="error"]': {
            color: 'red.500',
          },
        },
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'gray.600',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: 'gray.500',
        },
        'body .table-wraper': {
          bg: 'gray.800 !important',
          borderRadius: 'lg !important',
          overflowX: 'auto',
          minH: 'calc(100vh - 120px) !important',
        },
        'body td': {
          borderColor: 'transparent !important',
          p: '13px 16px !important',
          fontSize: 'sm !important',
          color: 'gray.100 !important',
          maxW: '250px !important',
        },
        'body th': {
          borderColor: 'gray.700 !important',
          p: '17px 16px 14px !important',
          fontSize: 'xs !important',
          textTransform: 'capitalize !important',
          color: 'white !important',
        },
      }),
    },
    sizes: {
      container: {
        xl: '1200px',
      },
    },
    colors: {
      primary: {
        900: '#12110e',
        800: '#24221d',
        700: '#484339',
        600: '#908672',
        500: '#b4a88f',
        400: '#bcb19a',
        300: '#cbc2b1',
        200: '#dad4c7',
        100: '#f0eee9',
        50: '#f8f6f4',
      },
      gray: {
        900: '#000000',
        800: '#131313',
        700: '#272727',
        600: '#4d4d4d',
        500: '#666666',
        400: '#999999',
        300: '#b3b3b3',
        200: '#cccccc',
        100: '#e6e6e6',
        50: '#ffffff',
      },
    },
    shadows: {
      md: '0 5px 20px 0 #00000010',
      lg: '0 5px 42px 0 #00000014',
    },
    components: {
      Button: {
        variants: {
          solid: {
            bg: 'primary.500',
            color: 'gray.900',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'primary.400',
              color: 'gray.900',
            },
            _active: {
              bg: 'primary.600',
              color: 'gray.900',
            },
          },
          delete: {
            bg: 'red.600',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'white.500',
              color: 'white',
            },
            _active: {
              bg: 'white.700',
              color: 'white',
            },
          },
          outline: {
            color: 'gray.100',
            borderColor: 'gray.100',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'primary.400',
              color: 'white',
            },
            _active: {
              bg: 'primary.600',
              color: 'white',
            },
          },
          ghost: {
            bg: 'transparent',
            color: 'gray.100',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'transparent',
              color: 'white',
            },
            _active: {
              bg: 'transparent',
              color: 'white',
            },
          },
          gray: {
            bg: 'gray.700',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            shadow:
              'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(98, 110, 156) 0px 1px 2px 0px inset, rgba(0, 0, 0, 0.16) 0px 2px 3px 0px, rgba(0, 0, 0, 0.4) 0px -1px 2px 0px inset',
            _hover: {
              bg: 'gray.600',
              color: 'white',
            },
            _active: {
              bg: 'gray.800',
              color: 'white',
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

export default theme;
