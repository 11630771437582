import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import CategoriesTable from '../components/tables/ctaegoriesTable';

export default function Categories() {
  return (
    <Box sx={{ py: '20px' }}>
      <Container maxW="container.xl">
        <CategoriesTable />
      </Container>
    </Box>
  );
}
