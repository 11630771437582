import { Box, Container } from '@chakra-ui/react';
import React from 'react';
import PostsForm from '../components/forms/portfolioForm';

export default function AddBlog() {
  return (
    <Box>
      <Container maxW="container.xl">
        <PostsForm />
      </Container>
    </Box>
  );
}
