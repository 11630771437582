/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Stack,
  Heading,
  Divider,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import InputField from '../../components/ui/textField';
import TextAreaField from '../ui/textAreaField';
import DeleteDialog from '../ui/deleteDialog';
import { IoArrowBackOutline } from 'react-icons/io5';
import {
  useCreateCategoryMutation,
  useLazyGetCategoryQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} from '../../api';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const generateSlug = (text: String) => {
  const slug = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');

  return slug;
};

const initialValues = {
  name: '',
  slug: '',
  description: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('Title is required'),
  slug: Yup.string().required('Slug is required'),
  description: Yup.string(),
});

export default function CategoriesForm() {
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [createCategory, response] = useCreateCategoryMutation();
  const [updateCategory, updateResponse] = useUpdateCategoryMutation();
  const [deleteCategory, deleteResponse] = useDeleteCategoryMutation();
  const [getCategory, getCategoryResponse] = useLazyGetCategoryQuery();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      if (!id) {
        createCategory(values)
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Category Created',
              description: 'Category has been successfully created',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            navigate(`/categories/${data.data.id}`);
          })
          .catch(err => {
            toast({
              title: 'Category Creation Failed',
              description: err.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          });
      } else {
        updateCategory({ id, body: values })
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Category Updated',
              description: 'Category has been successfully updated',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          })
          .catch(err => {
            toast({
              title: 'Category Update Failed',
              description: err.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      getCategory(id)
        .then((data: any) => {
          if (data.error) {
            throw new Error(
              data?.error?.data?.message || 'Something went wrong',
            );
          }
          formik.setValues({
            name: data.data.name,
            slug: data.data.slug,
            description: data.data.description,
          });
        })
        .catch(err => {
          toast({
            title: 'Category Fetch Failed',
            description: err.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          navigate('/categories');
        });
    }
  }, [id]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast({
        title: 'Category deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      navigate('/categories');
    }

    if (deleteResponse.isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [deleteResponse.isSuccess, toast, deleteResponse.isError]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            base: 'column',
            md: 'row',
          },
          minHeight: '100vh',
        }}
      >
        <Stack
          spacing={6}
          sx={{
            width: {
              base: '100%',
              md: 'calc(100% - 300px)',
            },
            borderRight: {
              base: 'none',
              md: '1px solid',
            },
            borderColor: {
              base: 'none',
              md: 'gray.700',
            },
            paddingRight: {
              base: '0',
              md: '20px',
            },
            py: '20px',
          }}
        >
          <Heading fontSize="md" color="gray.200">
            <IconButton
              variant="ghost"
              size="sm"
              fontSize="25px"
              as={Link}
              to="/categories"
              sx={{
                svg: {
                  position: 'relative',
                  left: '-6px',
                  top: '-2px',
                },
              }}
              icon={<IoArrowBackOutline />}
              aria-label="Back"
            />{' '}
            {id ? 'Edit Category' : 'Add New Category'}
          </Heading>
          <InputField
            label="Name"
            placeholder="Enter name"
            size="lg"
            name="name"
            value={formik.values.name}
            onChange={e => {
              formik.setFieldValue('name', e.target.value);
              formik.setFieldValue('slug', generateSlug(e.target.value));
            }}
            error={formik.touched.name && formik.errors.name}
          />
          <InputField
            label="Slug"
            placeholder="Enter slug"
            size="lg"
            name="slug"
            value={formik.values.slug}
            onChange={e => {
              formik.setFieldValue('slug', generateSlug(e.target.value));
            }}
            error={formik.touched.slug && formik.errors.slug}
          />
          <TextAreaField
            label="Description"
            placeholder="Enter description"
            size="lg"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && formik.errors.description}
          />
        </Stack>
        <Stack
          sx={{
            width: {
              base: '100%',
              md: '300px',
            },
            py: '30px',
            pt: '0',
            pl: {
              base: '0',
              md: '20px',
            },
            position: 'sticky',
            top: '0',
          }}
          spacing={6}
        >
          <Stack
            sx={{
              pb: '3',
              position: 'sticky',
              top: '20px',
            }}
            spacing={6}
          >
            <Stack spacing={4} direction="row">
              {id ? (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getCategoryResponse.isLoading ||
                      deleteResponse.isLoading
                    }
                    onClick={() => setDeleteId(id)}
                  >
                    Delete
                  </Button>
                  <Button
                    type="submit"
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getCategoryResponse.isFetching ||
                      deleteResponse.isLoading
                    }
                    isLoading={
                      updateResponse.isLoading ||
                      getCategoryResponse.isLoading ||
                      getCategoryResponse.isFetching
                    }
                    loadingText={
                      updateResponse.isLoading ? 'Updating...' : 'Please wait'
                    }
                  >
                    Save Changes
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    as={Link}
                    to="/categories"
                    isDisabled={
                      response.isLoading || getCategoryResponse.isFetching
                    }
                  >
                    Discard
                  </Button>
                  <Button
                    type="submit"
                    flex={1}
                    isDisabled={
                      response.isLoading || getCategoryResponse.isFetching
                    }
                    isLoading={
                      response.isLoading ||
                      getCategoryResponse.isFetching ||
                      getCategoryResponse.isLoading
                    }
                    loadingText={
                      response.isLoading ? 'Creating...' : 'Please wait'
                    }
                  >
                    Create
                  </Button>
                </>
              )}
            </Stack>
            <Divider borderColor="gray.700" />
          </Stack>
        </Stack>
      </Box>
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Category"
        isLoading={deleteResponse.isLoading}
        onDelete={() => {
          deleteCategory(deleteId || '');
        }}
      />
    </form>
  );
}
