import { HomeIcon } from '../icons';
import { HiMiniListBullet } from 'react-icons/hi2';
import { PiImagesFill } from 'react-icons/pi';

export const menuLinks = [
  {
    label: 'Portfolio',
    url: '/portfolio',
    icon: HomeIcon,
  },
  {
    label: 'Categories',
    url: '/categories',
    icon: HiMiniListBullet,
  },
  {
    label: 'Media',
    url: '/media',
    icon: PiImagesFill,
  },
];
