import React from 'react';
import {
  Box,
  Tag,
  TagLabel,
  Tooltip,
  useClipboard,
  IconButton,
} from '@chakra-ui/react';
import { IoCopyOutline } from 'react-icons/io5';

interface Props {
  text: string;
}

export default function IdComponent(props: Props) {
  const { text } = props;
  const { hasCopied, onCopy } = useClipboard(text);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tag
        sx={{
          borderRadius: 'md',
          fontSize: '10px',
          fontWeight: '600',
          letterSpacing: 'wider',
          color: 'white',
          bg: `gray.700`,
          pr: 0,
        }}
      >
        <TagLabel>{text}</TagLabel>
        <Tooltip
          label={hasCopied ? 'Copied' : 'Copy'}
          closeDelay={500}
          placement="top"
          hasArrow
          sx={{
            borderRadius: 'md',
            fontSize: 'xs',
          }}
        >
          <IconButton
            aria-label="Copy"
            icon={<IoCopyOutline />}
            sx={{
              bg: 'transparent !important',
              color: 'white !important',
              borderLeft: '1px solid',
              borderColor: 'gray.600',
              ml: 2,
              borderRadius: '0',
            }}
            size="xs"
            variant="ghost"
            onClick={onCopy}
          />
        </Tooltip>
      </Tag>
    </Box>
  );
}
