import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import Select from 'react-select';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

interface SelectFieldProps {
  maxW?: number | string;
  minW?: number | string;
  error?: string | undefined | boolean;
  label?: string;
  options?: { value: string; label: string }[];
  onChange?: (value: any) => void;
  value?: any;
  placeholder?: string;
  iconLeft?: React.ReactNode;
  fullWidth?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
}

export default function SelectField(props: SelectFieldProps) {
  const {
    label,
    error,
    options,
    onChange,
    value,
    placeholder,
    iconLeft,
    maxW,
    minW,
    fullWidth,
    isClearable,
    disabled,
  } = props;
  return (
    <FormControl
      sx={{
        ...(fullWidth && {
          flex: 1,
        }),
        ...(maxW && {
          maxWidth: maxW,
        }),
        ...(minW && {
          minWidth: minW,
        }),
        width: 'auto',
        '.react-select__control': {
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
          height: 'auto',
          border: '1px solid',
          borderColor: 'gray.700',
          backgroundColor: 'gray.800',
          borderRadius: 'md',
          minHeight: '40px',
          fontSize: 'sm',
          fontWeight: '500',
          pl: iconLeft ? '25px' : '0',
          ':hover': {
            borderColor: 'gray.600',
          },
          '&.react-select__control--is-focused': {
            borderColor: 'primary.500',
            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.4)',
          },
        },
        '.react-select__value-container': {
          padding: '0 10px',
        },
        '.react-select__placeholder': {
          color: 'gray.300',
        },
        '.react-select__single-value': {
          color: 'white',
        },
        '.react-select__input-container': {
          color: 'white',
        },
        '.react-select__indicator-separator': {
          display: 'none',
        },
        '.react-select__menu': {
          borderRadius: 'md',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          border: '1px solid',
          borderColor: 'gray.700',
          backgroundColor: 'gray.800',
          p: '5px 8px',
          zIndex: 99,
        },
        '.react-select__option': {
          color: 'white',
          borderRadius: 'md',
          transition: 'all 0.2s',
          fontSize: 'sm',
          mb: 1,
          p: '5px 10px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'gray.700',
          },
        },
        '.react-select__option--is-focused': {
          backgroundColor: 'gray.700',
        },
        '.react-select__option--is-selected': {
          backgroundColor: 'gray.600 !important',
        },
      }}
      isInvalid={!!error}
    >
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <InputGroup
        sx={{
          isolation: 'auto',
          width: '100%',
        }}
      >
        {iconLeft && (
          <InputLeftElement
            sx={{
              color: 'gray.200',
            }}
            pointerEvents="none"
            children={iconLeft}
          />
        )}
        <Box
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          <Select
            isDisabled={disabled}
            isClearable={isClearable}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            value={value && options?.find(e => e.value === value)}
            classNamePrefix="react-select"
            components={{
              DropdownIndicator: () => (
                <Box
                  sx={{
                    width: '28px',
                    color: 'gray.200',
                  }}
                >
                  <MdOutlineKeyboardArrowDown size={24} />
                </Box>
              ),
            }}
          />
        </Box>
      </InputGroup>
      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
