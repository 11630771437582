import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLogged: false,
    showSidebar: false,
  },
  reducers: {
    loginReducer: (state, action) => {
      state.isLogged = true;
    },
    logoutReducer: state => {
      state.isLogged = false;
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
});

export const { loginReducer, logoutReducer, setShowSidebar } = appSlice.actions;
export default appSlice.reducer;
