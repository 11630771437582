/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Stack,
  Heading,
  Divider,
  useToast,
  IconButton,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import InputField from '../ui/textField';
import DeleteDialog from '../ui/deleteDialog';
import SelectField from '../ui/selectField';
import RichEditor from '../ui/richEditor';
import MediaPicker from '../ui/mediaPicker';
import { IoArrowBackOutline } from 'react-icons/io5';
import {
  useCreatePortfolioMutation,
  useLazyGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetCategoriesQuery,
} from '../../api';
import TextAreaField from '../ui/textAreaField';
import TagInputField from '../ui/tagInput';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const generateSlug = (text: String) => {
  const slug = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');

  return slug;
};

const initialValues = {
  type: 'normal',
  tagLine: '',
  tags: [],
  category: null,
  clientLogo: null,
  isFeatured: false,
  thumbnail: null,
  slug: '',
  arValues: {
    lensName: '',
    description: '',
    qrImage: null,
    lensPreviewVideo: null,
    lensLink: '',
  },
  normalValues: {
    title: '',
    body: '',
    horizontalImage: null,
  },
};

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  tagLine: Yup.string().default(''),
  tags: Yup.array().of(Yup.string()).default([]),
  category: Yup.string().nullable().default(null),
  clientLogo: Yup.string().nullable().default(null),
  isFeatured: Yup.boolean().default(false),
  thumbnail: Yup.string().required('Thumbnail is required'),
  slug: Yup.string().required('Slug is required'),
  arValues: Yup.mixed().when('type', {
    is: 'ar',
    then: () =>
      Yup.object().shape({
        lensName: Yup.string().required('Lens Name is required'),
        description: Yup.string().default(''),
        qrImage: Yup.string().nullable().default(null),
        lensPreviewVideo: Yup.string().nullable().default(null),
        lensLink: Yup.string().default(''),
      }),
    otherwise: () => Yup.mixed().strip(),
  }),
  normalValues: Yup.mixed().when('type', {
    is: 'normal',
    then: () =>
      Yup.object().shape({
        title: Yup.string().required('Title is required'),
        body: Yup.string().default(''),
        horizontalImage: Yup.string().nullable().default(null),
      }),
    otherwise: () => Yup.mixed().strip(),
  }),
});

export default function PortfolioForm() {
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [content, setContent] = React.useState('');
  const [createPortfolio, response] = useCreatePortfolioMutation();
  const [updatePortfolio, updateResponse] = useUpdatePortfolioMutation();
  const [deletePortfolio, deleteResponse] = useDeletePortfolioMutation();
  const [getPortfolio, getPortfolioResponse] = useLazyGetPortfolioQuery();
  const { data: categories } = useGetCategoriesQuery({ limit: 10000 });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const body = {
        type: values.type,
        tagLine: values.tagLine,
        tags: values.tags,
        category: values.category,
        clientLogo: values.clientLogo,
        isFeatured: values.isFeatured,
        thumbnail: values.thumbnail,
        slug: values.slug,
        ...(values.type === 'ar' && { arValues: values.arValues }),
        ...(values.type === 'normal' && { normalValues: values.normalValues }),
      };
      if (!id) {
        createPortfolio(body)
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Portfolio Created',
              description: 'Portfolio has been successfully created',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
            navigate(`/portfolio/${data.data.id}`);
          })
          .catch(err => {
            toast({
              title: 'Portfolio Creation Failed',
              description: err.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          });
      } else {
        updatePortfolio({ id, body })
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Portfolio Updated',
              description: 'Portfolio has been successfully updated',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          })
          .catch(err => {
            toast({
              title: 'Portfolio Update Failed',
              description: err.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      getPortfolio(id)
        .then((data: any) => {
          if (data.error) {
            throw new Error(
              data?.error?.data?.message || 'Something went wrong',
            );
          }
          setContent(data.data?.normalValues?.body || '');
          formik.setValues({
            type: data.data.type,
            tagLine: data.data?.tagLine || '',
            tags: data.data?.tags || [],
            category: data.data?.category?.id || null,
            clientLogo: data.data?.clientLogo?.id || null,
            isFeatured: data.data?.isFeatured || false,
            thumbnail: data.data?.thumbnail?.id || null,
            slug: data.data?.slug || '',
            arValues: {
              lensName: data.data?.arValues?.lensName || '',
              description: data.data?.arValues?.description || '',
              qrImage: data.data?.arValues?.qrImage?.id || null,
              lensPreviewVideo:
                data.data?.arValues?.lensPreviewVideo?.id || null,
              lensLink: data.data?.arValues?.lensLink || '',
            },
            normalValues: {
              title: data.data?.normalValues?.title || '',
              body: data.data?.normalValues?.body || '',
              horizontalImage:
                data.data?.normalValues?.horizontalImage?.id || null,
            },
          });
        })
        .catch(err => {
          toast({
            title: 'Portfolio Fetch Failed',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          navigate('/portfolio');
        });
    }
  }, [id]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast({
        title: 'Portfolio deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      navigate('/portfolio');
    }

    if (deleteResponse.isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [deleteResponse.isSuccess, toast, deleteResponse.isError]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            base: 'column',
            md: 'row',
          },
          minHeight: '100vh',
        }}
      >
        <Stack
          spacing={6}
          sx={{
            width: {
              base: '100%',
              md: 'calc(100% - 300px)',
            },
            borderRight: {
              base: 'none',
              md: '1px solid',
            },
            borderColor: {
              base: 'none',
              md: 'gray.700',
            },
            paddingRight: {
              base: '0',
              md: '20px',
            },
            py: '20px',
          }}
        >
          <Heading fontSize="md" color="gray.200">
            <IconButton
              variant="ghost"
              size="sm"
              fontSize="25px"
              as={Link}
              to="/portfolio"
              sx={{
                svg: {
                  position: 'relative',
                  left: '-6px',
                  top: '-2px',
                },
              }}
              icon={<IoArrowBackOutline />}
              aria-label="Back"
            />{' '}
            {id ? 'Edit Portfolio' : 'Add New Portfolio'}
          </Heading>
          {formik.values.type === 'normal' && (
            <>
              <InputField
                label="Title"
                value={formik.values.normalValues.title}
                onChange={e => {
                  formik.setFieldValue('normalValues.title', e.target.value);
                  formik.setFieldValue('slug', generateSlug(e.target.value));
                }}
                error={
                  formik.touched.normalValues?.title &&
                  formik.errors.normalValues?.title
                }
              />
            </>
          )}
          {formik.values.type === 'ar' && (
            <InputField
              label="Lens Name"
              value={formik.values.arValues.lensName}
              onChange={e => {
                formik.setFieldValue('arValues.lensName', e.target.value);
                formik.setFieldValue('slug', generateSlug(e.target.value));
              }}
              error={
                formik.touched.arValues?.lensName &&
                formik.errors.arValues?.lensName
              }
            />
          )}
          <InputField
            label="Slug"
            value={formik.values.slug}
            onChange={e =>
              formik.setFieldValue('slug', generateSlug(e.target.value))
            }
            error={formik.touched?.slug && formik.errors?.slug}
          />
          <InputField
            label="Tagline"
            value={formik.values.tagLine}
            onChange={e => formik.setFieldValue('tagLine', e.target.value)}
            error={formik.touched.tagLine && formik.errors.tagLine}
          />
          {formik.values.type === 'ar' && (
            <TextAreaField
              label="Description"
              value={formik.values.arValues.description}
              onChange={e =>
                formik.setFieldValue('arValues.description', e.target.value)
              }
              error={
                formik.touched.arValues?.description &&
                formik.errors.arValues?.description
              }
            />
          )}
          {formik.values.type === 'ar' && (
            <InputField
              label="Lens Link"
              value={formik.values.arValues.lensLink}
              onChange={e =>
                formik.setFieldValue('arValues.lensLink', e.target.value)
              }
              error={
                formik.touched.arValues?.lensLink &&
                formik.errors.arValues?.lensLink
              }
            />
          )}
          {formik.values.type === 'normal' && (
            <RichEditor
              label="Content"
              value={content}
              onChange={e => formik.setFieldValue('normalValues.body', e)}
              error={
                formik.touched.normalValues?.body &&
                formik.errors.normalValues?.body
              }
            />
          )}
          <Divider borderColor="gray.700" />
          <TagInputField
            label="Tags"
            value={formik.values.tags}
            onChange={(e: any) => formik.setFieldValue('tags', e)}
            error={formik.touched?.tags && formik.errors?.tags}
          />
        </Stack>
        <Stack
          sx={{
            width: {
              base: '100%',
              md: '300px',
            },
            py: '30px',
            pt: '0',
            pl: {
              base: '0',
              md: '20px',
            },
            position: 'sticky',
            top: '0',
          }}
          spacing={6}
        >
          <Stack
            sx={{
              pt: '30px',
              pb: '10px',
              position: 'sticky',
              top: '0',
              bg: 'gray.900',
              zIndex: 1,
            }}
            spacing={6}
          >
            <Stack spacing={4} direction="row">
              {id ? (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getPortfolioResponse.isFetching ||
                      deleteResponse.isLoading
                    }
                    onClick={() => setDeleteId(id)}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={formik.submitForm}
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getPortfolioResponse.isFetching ||
                      deleteResponse.isLoading
                    }
                    isLoading={
                      updateResponse.isLoading || getPortfolioResponse.isLoading
                    }
                    loadingText={
                      updateResponse.isLoading ? 'Updating...' : 'Please wait'
                    }
                  >
                    Save Changes
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    as={Link}
                    to="/portfolio"
                    isDisabled={
                      response.isLoading || getPortfolioResponse.isFetching
                    }
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={formik.submitForm}
                    flex={1}
                    isDisabled={
                      response.isLoading || getPortfolioResponse.isFetching
                    }
                    isLoading={
                      response.isLoading || getPortfolioResponse.isFetching
                    }
                    loadingText={
                      response.isLoading ? 'Creating...' : 'Please wait'
                    }
                  >
                    Create
                  </Button>
                </>
              )}
            </Stack>
            <Divider borderColor="gray.700" />
          </Stack>
          <SelectField
            disabled={id ? true : false}
            label="Portfolio Type"
            value={formik.values.type}
            onChange={(e: any) => formik.setFieldValue('type', e?.value)}
            error={formik.touched.type && formik.errors.type}
            options={[
              {
                label: 'Post',
                value: 'normal',
              },
              {
                label: 'AR',
                value: 'ar',
              },
            ]}
          />
          <SelectField
            label="Category"
            value={formik.values.category}
            onChange={(e: any) => formik.setFieldValue('category', e?.value)}
            error={formik.touched.category && formik.errors.category}
            options={
              categories?.docs?.map((cat: any) => ({
                label: cat.name,
                value: cat.id,
              })) || []
            }
          />
          <MediaPicker
            label="Thumbnail"
            value={formik.values.thumbnail}
            onChange={(e: any) => formik.setFieldValue('thumbnail', e?.value)}
            error={formik.touched.thumbnail && formik.errors.thumbnail}
            type="image"
          />
          {formik.values.type === 'normal' && (
            <MediaPicker
              label="Horizontal Thumbnail"
              value={formik.values.normalValues.horizontalImage}
              onChange={(e: any) =>
                formik.setFieldValue('normalValues.horizontalImage', e?.value)
              }
              error={
                formik.touched.normalValues?.horizontalImage &&
                formik.errors.normalValues?.horizontalImage
              }
              type="image"
            />
          )}
          {formik.values.type === 'ar' && (
            <>
              <MediaPicker
                label="QR Image"
                value={formik.values.arValues.qrImage}
                onChange={(e: any) =>
                  formik.setFieldValue('arValues.qrImage', e?.value)
                }
                error={
                  formik.touched.arValues?.qrImage &&
                  formik.errors.arValues?.qrImage
                }
                type="image"
              />
              <MediaPicker
                label="Lens Preview Video"
                value={formik.values.arValues.lensPreviewVideo}
                onChange={(e: any) =>
                  formik.setFieldValue('arValues.lensPreviewVideo', e?.value)
                }
                error={
                  formik.touched.arValues?.lensPreviewVideo &&
                  formik.errors.arValues?.lensPreviewVideo
                }
                type="video"
              />
            </>
          )}
          <MediaPicker
            label="Client Logo"
            value={formik.values.clientLogo}
            onChange={(e: any) => formik.setFieldValue('clientLogo', e?.value)}
            error={formik.touched.clientLogo && formik.errors.clientLogo}
            type="image"
          />
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel htmlFor="featured" mb="0">
              Featured
            </FormLabel>
            <Switch
              id="featured"
              isChecked={formik.values.isFeatured}
              onChange={e =>
                formik.setFieldValue('isFeatured', e.target.checked)
              }
            />
          </FormControl>
        </Stack>
      </Box>
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Portfolio"
        isLoading={deleteResponse.isLoading}
        onDelete={() => {
          deletePortfolio(deleteId || '');
        }}
      />
    </form>
  );
}
