/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Checkbox,
  CircularProgress,
} from '@chakra-ui/react';
import { LuPlus } from 'react-icons/lu';
import { useGetMediaQuery, useDeleteMediaMutation } from '../../api';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import DeleteDialog from '../ui/deleteDialog';
import EmptyResult from '../ui/emptyResult';
import UploaderPreview from '../ui/uploaderPeview';
import IdComponent from '../ui/idComponent';

export default function MediaTable() {
  const [selected, setSelected] = useState<String[]>([]);
  const toast = useToast();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [file, setFile] = useState<File[] | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, isFetching, refetch } = useGetMediaQuery(filter);
  const [deleteMedia, { isLoading: isDeleting, isError, isSuccess }] =
    useDeleteMediaMutation();

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Media deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setSelected([]);
      setShowDeleteDialog(false);
      refetch();
    }

    if (isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSelected([]);
      setShowDeleteDialog(false);
    }
  }, [isSuccess, toast, isError]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            base: 'flex-start',
            md: 'center',
          },
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
          lineHeight={{
            base: '40px',
            md: '1.3',
          }}
        >
          Media
        </Heading>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
          }}
        >
          {selected.length > 0 && (
            <Button onClick={() => setShowDeleteDialog(true)} variant="delete">
              Delete
            </Button>
          )}
          <Button
            sx={{
              input: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 0,
                cursor: 'pointer',
                opacity: 0,
              },
            }}
            position="relative"
            rightIcon={<LuPlus size={20} />}
            minW={120}
          >
            <Text pt="1px">Add Media</Text>
            <input
              type="file"
              accept="image/*, video/*"
              multiple
              disabled={isUploading}
              onChange={(e: any) => {
                setFile(Array.from(e.target.files));
                e.target.value = '';
              }}
            />
          </Button>
        </Box>
      </Box>
      {file?.length && file?.length > 0 && (
        <Box mb={6}>
          <UploaderPreview
            image={file as any}
            onSuccess={() => {
              refetch();
              setFile(null);
            }}
            onRemove={() => setFile(null)}
            isUploading={false}
            setIsUploading={setIsUploading}
          />
        </Box>
      )}
      <Box position="relative" className="table-wraper">
        {isFetching && (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="100"
          >
            <CircularProgress isIndeterminate color="blue.500" />
          </Box>
        )}
        <Table>
          <Thead>
            <Tr>
              <Th width="30px">
                <Checkbox
                  colorScheme="blue"
                  sx={{
                    borderColor: 'gray.500',
                  }}
                  isIndeterminate={
                    selected.length > 0 && selected.length < data?.docs?.length
                  }
                  isChecked={
                    data?.docs?.length === selected.length && data?.docs?.length
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      setSelected(
                        data?.docs?.map((post: any) => post.id) || [],
                      );
                    } else {
                      setSelected([]);
                    }
                  }}
                />
              </Th>
              <Th w={'105px'} maxW={'105px'}>
                Thumbnail
              </Th>
              <Th>Name</Th>
              <Th>Url</Th>
              <Th width={150}>Created At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <Checkbox
                      colorScheme="blue"
                      sx={{
                        borderColor: 'gray.500',
                      }}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelected([...selected, post.id]);
                        } else {
                          setSelected(selected.filter(id => id !== post.id));
                        }
                      }}
                      isChecked={selected.includes(post.id)}
                    />
                  </Td>
                  <Td>
                    {post.type.includes('image') ? (
                      <Box
                        as="img"
                        src={post.url}
                        alt="preview"
                        width={50}
                        height={50}
                        objectFit="cover"
                        borderRadius="10px"
                        bg="gray.700"
                      />
                    ) : (
                      <Box
                        sx={{
                          video: {
                            borderRadius: '10px',
                            width: '50px !important',
                            height: '50px !important',
                            objectFit: 'cover',
                            bg: 'gray.700',
                          },
                        }}
                      >
                        <video width="50" height="50">
                          <source src={post.url} type="video/mp4" />
                        </video>
                      </Box>
                    )}
                  </Td>
                  <Td>
                    <Text
                      sx={{
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {post.name}
                    </Text>
                  </Td>
                  <Td>
                    <IdComponent text={post.url} />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">{post.createdAt}</Moment>
                  </Td>
                </Tr>
              ))}
            {isLoading && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isFetching && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.totalPages}
        onPageChange={(page: any) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setFilter({ ...filter, page: page });
          setSelected([]);
        }}
        currentPage={filter.page}
      />
      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        name="Media"
        isLoading={isDeleting}
        onDelete={() => {
          deleteMedia({ ids: selected });
        }}
      />
    </Box>
  );
}
