import React from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { TagsInput } from 'react-tag-input-component';

interface TagInputFieldProps {
  error?: string | never[] | string[] | undefined;
  label?: string;
  onChange?: (value: any) => void;
  value?: any;
  placeholder?: string;
}

export default function TagInputField(props: TagInputFieldProps) {
  const { label, error, onChange, value, placeholder } = props;
  return (
    <FormControl
      sx={{
        '.rti--container': {
          border: '1px solid',
          borderColor: 'gray.700',
          backgroundColor: 'gray.800',
          borderRadius: 'md',
          minHeight: '40px',
          display: 'flex',
          alignItems: 'center',
          padding: 2,
        },
        '.rti--input': {
          backgroundColor: 'transparent',
        },
        '.rti--tag': {
          backgroundColor: 'gray.600',
          color: 'white',
          borderRadius: 'md',
          fontSize: 'sm',
          fontWeight: '600',
          pl: 2,
        },
      }}
      isInvalid={!!error}
    >
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <TagsInput value={value} onChange={onChange} placeHolder={placeholder} />
      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
