/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from '@chakra-ui/react';
import { LuPlus } from 'react-icons/lu';
import InputField from '../ui/textField';
import { IoSearchOutline } from 'react-icons/io5';
import { useGetPortfoliosQuery, useDeletePortfolioMutation } from '../../api';
import { Link } from 'react-router-dom';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import { useDebounce } from 'use-debounce';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import DeleteDialog from '../ui/deleteDialog';
import ActionButton from '../ui/actionButton';
import EmptyResult from '../ui/emptyResult';

export default function PortfolioTable() {
  const toast = useToast();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 15,
  });
  const [search, setSearch] = useState<string>('');
  const [searchValue] = useDebounce(search, 1300);
  const { data, isFetching, refetch } = useGetPortfoliosQuery({
    page: filter.page,
    limit: filter.limit,
    ...(searchValue && { search: searchValue }),
  });
  const [deletePortfolio, { isLoading: isDeleting, isError, isSuccess }] =
    useDeletePortfolioMutation();

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Portfolio deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      refetch();
    }

    if (isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [isSuccess, toast, isError]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            base: 'flex-start',
            md: 'center',
          },
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
          lineHeight={{
            base: '40px',
            md: '1.3',
          }}
        >
          Portfolio
        </Heading>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              minWidth: '250px',
            }}
          >
            <InputField
              placeholder="Search Portfolio"
              value={search}
              onChange={e => setSearch(e.target.value)}
              iconLeft={<IoSearchOutline size={20} />}
            />
          </Box>
          <Button
            as={Link}
            to="/portfolio/new"
            colorScheme="blue"
            rightIcon={<LuPlus size={20} />}
            minW={120}
          >
            <Text pt="1px">Add Portfolio</Text>
          </Button>
        </Box>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th>Thumbnail</Th>
              <Th>Title/Name</Th>
              <Th>Category</Th>
              <Th>Featured</Th>
              <Th width={150}>Created At</Th>
              <Th width="150px">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        overflow: 'hidden',
                        borderRadius: 'md',
                        bgColor: 'gray.700',
                        img: {
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    >
                      {post?.thumbnail?.url && (
                        <img
                          src={post?.thumbnail?.url}
                          alt="thumbnail"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </Box>
                  </Td>
                  <Td
                    sx={{
                      maxWidth: '350px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {post?.normalValues?.title ||
                      post?.arValues?.lensName ||
                      post?.tagLine}
                  </Td>
                  <Td>{post?.category?.name || 'Uncategorized'}</Td>
                  <Td>
                    {post?.isFeatured ? (
                      <Box
                        sx={{
                          bgColor: 'yellow.400',
                          color: 'black',
                          borderRadius: 'md',
                          px: 2,
                          py: 1,
                          fontSize: '12px',
                          maxWidth: 'max-content',
                          fontWeight: 700,
                        }}
                      >
                        Featured
                      </Box>
                    ) : (
                      ''
                    )}
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">{post.createdAt}</Moment>
                  </Td>
                  <Td>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <ActionButton
                        type="edit"
                        link={`/portfolio/${post.id}`}
                      />
                      <ActionButton
                        type="delete"
                        onClick={() => setDeleteId(post.id)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            {isFetching && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isFetching && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.totalPages}
        onPageChange={(page: any) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setFilter({ ...filter, page: page });
        }}
        currentPage={filter.page}
      />
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Portfolio"
        isLoading={isDeleting}
        onDelete={() => {
          deletePortfolio(deleteId || '');
        }}
      />
    </Box>
  );
}
