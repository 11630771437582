/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from '@chakra-ui/react';
import { LuPlus } from 'react-icons/lu';
import { useGetCategoriesQuery, useDeleteCategoryMutation } from '../../api';
import { Link } from 'react-router-dom';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import DeleteDialog from '../ui/deleteDialog';
import ActionButton from '../ui/actionButton';
import EmptyResult from '../ui/emptyResult';

export default function CategoriesTable() {
  const toast = useToast();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 15,
  });
  const { data, isLoading, isFetching, refetch } =
    useGetCategoriesQuery(filter);
  const [deleteCategory, { isLoading: isDeleting, isError, isSuccess }] =
    useDeleteCategoryMutation();

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Category deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      refetch();
    }

    if (isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [isSuccess, toast, isError]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            base: 'flex-start',
            md: 'center',
          },
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
          lineHeight={{
            base: '40px',
            md: '1.3',
          }}
        >
          Categories
        </Heading>
        <Button
          as={Link}
          to="/categories/new"
          colorScheme="blue"
          rightIcon={<LuPlus size={20} />}
          minW={120}
        >
          <Text pt="1px">Add Category</Text>
        </Button>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>slug</Th>
              <Th>Description</Th>
              <Th width={150}>Created At</Th>
              <Th width="150px">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <Text
                      sx={{
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {post.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      sx={{
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {post.slug}
                    </Text>
                  </Td>
                  <Td>{post.description}</Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">{post.createdAt}</Moment>
                  </Td>
                  <Td>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <ActionButton
                        type="edit"
                        link={`/categories/${post.id}`}
                      />
                      <ActionButton
                        type="delete"
                        onClick={() => setDeleteId(post.id)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            {(isLoading || isFetching) && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isLoading && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.totalPages}
        onPageChange={(page: any) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setFilter({ ...filter, page: page });
        }}
        currentPage={filter.page}
      />
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Category"
        isLoading={isDeleting}
        onDelete={() => {
          deleteCategory(deleteId || '');
        }}
      />
    </Box>
  );
}
