import React from 'react';
import { Box } from '@chakra-ui/react';
import { menuLinks } from './menuLinks';
import { LogoutIcon } from '../icons';
import { useCookies } from 'react-cookie';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowSidebar } from '../../redux/slices/app.slice';
import { useNavigate } from 'react-router-dom';
import { logoutReducer } from '../../redux/slices/app.slice';

const MenuItem = ({ url, icon, label, color, onClick }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        px: 3,
        py: 2,
        fontSize: '13px',
        position: 'relative',
        fontWeight: '600',
        color: color || 'white',
        backgroundColor: location.pathname?.includes(url)
          ? 'gray.700'
          : 'transparent',
        borderRadius: '6px',
        transition: 'all 0.2s',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'gray.700',
        },
        svg: {
          transition: 'all 0.2s',
          fontSize: 'xl',
          color: color || 'white',
        },
      }}
      as={!onClick ? Link : 'div'}
      {...(url && { to: url })}
      key={label}
      onClick={() => {
        dispatch(setShowSidebar(false));
        onClick && onClick();
      }}
    >
      <Box as={icon} />
      {label}
    </Box>
  );
};

export default function Menu() {
  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        px: 4,
        py: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.700',
          borderRadius: '24px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'gray.600',
        },
      }}
    >
      {menuLinks.map((link, i) => (
        <MenuItem {...link} key={i} />
      ))}
    </Box>
  );
}

export const BottomMenu = () => {
  const [, , deleteCookie] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        px: 2,
        py: 2,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <MenuItem
        icon={LogoutIcon}
        label="Logout"
        color="red"
        onClick={() => {
          deleteCookie('token');
          dispatch(logoutReducer());
          navigate('/login');
        }}
      />
    </Box>
  );
};
