import React from 'react';
import { FaInbox } from 'react-icons/fa';
import { Box, Text } from '@chakra-ui/react';

export default function EmptyResult() {
  return (
    <Box
      w="full"
      h="full"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      minH="calc(100vh - 120px)"
    >
      <Box as={FaInbox} size="68px" color="gray.200" mb={4} />
      <Text fontSize="sm" fontWeight="medium" color="gray.200">
        No results found
      </Text>
    </Box>
  );
}
