import React from 'react';
import SelectField from './selectField';
import { Box } from '@chakra-ui/react';
import { useGetMediaQuery } from '../../api';

interface MediaPickerProps {
  error?: string | undefined | boolean;
  label?: string;
  onChange?: (value: any) => void;
  value?: any;
  type: 'image' | 'video';
}

export default function MediaPicker(props: MediaPickerProps) {
  const [selected, setSelected] = React.useState<any>(null);
  const { label, error, onChange, value, type } = props;
  const { data: media } = useGetMediaQuery({ limit: 10000 });

  React.useEffect(() => {
    if (value) {
      const selectedMedia = media?.docs?.find((e: any) => e.id === value);
      setSelected(selectedMedia);
    } else {
      setSelected(null);
    }
  }, [media?.docs, value]);
  return (
    <Box>
      <SelectField
        label={label}
        value={value}
        onChange={onChange}
        isClearable
        options={
          media?.docs
            ?.filter((e: any) => e.type?.includes(type))
            ?.map((med: any) => ({
              label: med.name,
              value: med.id,
            })) || []
        }
        error={error}
      />
      {selected && (
        <Box mt={3}>
          {selected.type.includes('image') ? (
            <Box
              as="img"
              src={selected.url}
              alt="preview"
              width={70}
              height={70}
              borderRadius="10px"
              bg="gray.700"
              loading="lazy"
              objectFit="cover"
            />
          ) : (
            <Box
              sx={{
                video: {
                  borderRadius: '10px',
                  width: '70px !important',
                  height: '70px !important',
                  objectFit: 'cover',
                  bg: 'gray.700',
                },
              }}
            >
              <video width="70" height="70">
                <source src={selected.url} type="video/mp4" />
              </video>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
