import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout';
import Login from './views/login';
import Portfolio from './views/portfolio';
import AddPortfolio from './views/addPortfolio';
import Categories from './views/categories';
import AddCategory from './views/addCategory';
import Media from './views/media';
import store from './redux';
import { CookiesProvider } from 'react-cookie';
import AuthProvider from './components/authProvider';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <MantineProvider forceColorScheme="dark">
        <CookiesProvider>
          <Provider store={store}>
            <BrowserRouter>
              <AuthProvider>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Navigate to="/portfolio" />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/portfolio/new" element={<AddPortfolio />} />
                    <Route path="/portfolio/:id" element={<AddPortfolio />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/categories/new" element={<AddCategory />} />
                    <Route path="/categories/:id" element={<AddCategory />} />
                    <Route path="/media" element={<Media />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Navigate to="/portfolio" />} />
                </Routes>
              </AuthProvider>
            </BrowserRouter>
          </Provider>
        </CookiesProvider>
      </MantineProvider>
    </ChakraProvider>
  );
}

export default App;
