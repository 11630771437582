import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import Logo from '../sidebar/logo';
import { FiMenu } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSidebar } from '../../redux/slices/app.slice';

export default function Header() {
  const dispatch = useDispatch();
  const showSidebar = useSelector((state: any) => state.app.showSidebar);
  return (
    <Box
      sx={{
        padding: '16px',
        borderBottom: '1px solid',
        borderColor: 'gray.700',
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        position: 'sticky',
        top: 0,
        backgroundColor: 'gray.900',
        zIndex: 97,
        '@media (min-width: 1070px)': {
          display: 'none',
        },
      }}
    >
      <IconButton
        size="sm"
        sx={{
          fontSize: '24px',
          bg: 'transparent !important',
          border: 'none !important',
          boxShadow: 'none !important',
          color: 'white !important',
        }}
        aria-label="Menu"
        icon={<FiMenu />}
        onClick={() => {
          dispatch(setShowSidebar(!showSidebar));
        }}
      />
      <Box
        sx={{
          img: {
            height: '30px',
            width: 'auto',
          },
          p: {
            fontSize: '18px',
            fontWeight: '600',
          },
        }}
      >
        <Logo />
      </Box>
    </Box>
  );
}
